window.addEventListener(
    "load",
    () => {
        const buttons = document.querySelectorAll<HTMLButtonElement>(".accordion-section .question-button");

        buttons.forEach((button) => {
            const id = button.getAttribute("aria-controls");
            const controlledNode = id ? document.getElementById(id) : null;

            const showContent = () => {
                if (controlledNode) {
                    controlledNode.parentElement.classList.add("open");
                }
            };

            const hideContent = () => {
                if (controlledNode) {
                    controlledNode.parentElement.classList.remove("open");
                }
            };

            const toggleExpand = () => {
                if (button.getAttribute("aria-expanded") === "true") {
                    button.setAttribute("aria-expanded", "false");
                    hideContent();
                } else {
                    button.setAttribute("aria-expanded", "true");
                    showContent();
                }
            };

            button.setAttribute("aria-expanded", "false");
            hideContent();

            button.addEventListener("click", toggleExpand);
        });
    },
    false
);
