const nav = document.querySelector("#Navigation");

nav.querySelector(".menu-toggle").addEventListener("click", () => {
    nav.classList.toggle("open");
});

window.addEventListener("scroll", (e) => {
    // 120 because the nav bar is initially 200px high and collapses to 80px height
    nav.classList.toggle("collapsed", window.scrollY > 120);
});
