import "fslightbox";
import Swiper from "swiper";
import { Grid, Navigation, Pagination, Autoplay, EffectFade, EffectCreative, FreeMode } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/grid";

const toggleVideoIfExists = (slide: HTMLElement, on: boolean) => {
    const video = slide.querySelector("video");

    if (!video) {
        return;
    }

    if (on) {
        video.play();
    } else {
        video.pause();
    }
};

document.querySelectorAll<HTMLDivElement>(".hero-slider-section").forEach((container) => {
    const swiper = new Swiper(container, {
        modules: [Pagination, EffectCreative, Autoplay],
        loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        slideToClickedSlide: true,
        loopedSlides: 3,
        autoplay: {
            delay: 5000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
            waitForTransition: false,
        },
        spaceBetween: 100,
        pagination: {
            type: "bullets",
            el: ".pagination",
            bulletActiveClass: "active",
            bulletClass: "bullet",
            bulletElement: "li",
            clickable: true,
        },
        effect: "creative",
        creativeEffect: {
            prev: {
                translate: ["-100%", 0, 0],
                scale: 0.9,
                opacity: 0.4,
            },
            next: {
                translate: ["100%", 0, 0],
                scale: 0.9,
                opacity: 0.4,
            },
            limitProgress: 2,
        },
        on: {
            activeIndexChange: (swiper) => {
                toggleVideoIfExists(swiper.slides[swiper.activeIndex], true);
                toggleVideoIfExists(swiper.slides[swiper.previousIndex], false);
            },
            click: (swiper) => {},
        },
    });
});

document.querySelectorAll<HTMLDivElement>(".news-overview-section .container").forEach((container) => {
    const swiper = new Swiper(container, {
        breakpoints: {
            640: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
        spaceBetween: 24,
    });
});

document.querySelectorAll<HTMLDivElement>(".partners-and-sponsors-section .swiper").forEach((container) => {
    const swiper = new Swiper(container, {
        modules: [Pagination, Grid, FreeMode, Autoplay],
        spaceBetween: 20,
        autoplay: { delay: 2500, pauseOnMouseEnter: true, disableOnInteraction: false, waitForTransition: false },
        freeMode: {
            enabled: true,
        },
        grid: {
            rows: 2,
            fill: "row",
        },
        breakpoints: {
            320: {
                slidesPerView: 3,
            },
            640: {
                slidesPerView: 4,
            },
            900: {
                slidesPerView: 5,
            },
        },
    });
});

document.querySelectorAll<HTMLDivElement>(".voices-and-opinions-section").forEach((container) => {
    const swiper = new Swiper(container, {
        modules: [Pagination, EffectFade, Autoplay],
        slideActiveClass: "active",
        slidesPerView: 1,
        speed: 299,
        autoplay: { delay: 7500, pauseOnMouseEnter: true, disableOnInteraction: false, waitForTransition: false },
        pagination: {
            type: "bullets",
            el: ".pagination",
            bulletActiveClass: "active",
            bulletClass: "bullet",
            bulletElement: "li",
            clickable: true,
        },
        effect: "fade",
        fadeEffect: { crossFade: true },
    });
});

document.querySelectorAll<HTMLDivElement>(".person-overview-section .person-swiper").forEach((container) => {
    const swiper = new Swiper(container, {
        slidesPerView: "auto",
        spaceBetween: 16,
    });
});
