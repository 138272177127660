const tabSections = document.querySelectorAll<HTMLElement>(".tabs-section");

tabSections.forEach((section) => {
    const tabs = section.querySelectorAll<HTMLElement>(".tab");
    const tabContents = section.querySelectorAll<HTMLElement>(".tab-content");

    tabs.forEach((tab) => {
        tab.addEventListener("click", () => {
            tabs.forEach((tab) => {
                tab.classList.remove("active");
                tab.setAttribute("aria-expanded", "false");
            });
            tabContents.forEach((tabContent) => tabContent.classList.remove("visible"));

            tab.classList.add("active");
            tab.setAttribute("aria-expanded", "true");
            tab.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });

            const targetID = tab.getAttribute("aria-controls");
            section.querySelector(`#${targetID}`).classList.add("visible");
        });
    });
});
