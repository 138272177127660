export class Overlay {
    private container: HTMLElement;
    private overlay: HTMLDialogElement;
    private showButtons: NodeListOf<HTMLElement>;
    private closeButton: HTMLButtonElement;
    private content: HTMLElement;
    private items: NodeListOf<HTMLElement>;
    private prevButton: HTMLButtonElement;
    private nextButton: HTMLButtonElement;
    private currentIndex = 0;

    constructor(container: HTMLElement) {
        this.container = container;
        this.overlay = <HTMLDialogElement>this.container.querySelector(".overlay");
        this.showButtons = <NodeListOf<HTMLElement>>this.container.querySelectorAll(".show-overlay");
        this.closeButton = <HTMLButtonElement>this.overlay.querySelector(".close");
        this.content = <HTMLElement>this.overlay.querySelector(".overlay-content");
        this.items = <NodeListOf<HTMLElement>>this.content.querySelectorAll(".content");
        this.prevButton = <HTMLButtonElement>this.overlay.querySelector(".button-prev");
        this.nextButton = <HTMLButtonElement>this.overlay.querySelector(".button-next");

        this.showButtons.forEach((showButton) => {
            showButton.addEventListener("click", () => {
                if (showButton.dataset.index) {
                    this.goToSlide(Number(showButton.dataset.index));
                }
                this.overlay.showModal();
            });
        });

        this.closeButton.addEventListener("click", () => {
            this.overlay.close();
        });

        this.overlay.addEventListener("click", () => {
            this.overlay.close();
        });

        this.content.addEventListener("click", (e) => {
            e.stopPropagation();
        });

        if (this.prevButton && this.nextButton && this.items.length > 1) {
            this.prevButton.addEventListener("click", () => {
                this.goToSlide(this.currentIndex - 1);
            });
            this.nextButton.addEventListener("click", () => {
                this.goToSlide(this.currentIndex + 1);
            });
        }
    }

    private goToSlide(index: number) {
        if (index < 0) {
            index = this.items.length - 1;
        } else if (index > this.items.length - 1) {
            index = index % (this.items.length - 1);
        }
        this.items.forEach((item) => {
            if (item.classList.contains(`index-${index}`)) {
                item.classList.add("active");
            } else {
                item.classList.remove("active");
            }
        });
        this.currentIndex = index;
    }
}
