export default class ScrollToTop {

    private readonly visibleClass = 'visible';
    private readonly scrollYThreshold = 100;

    private isVisible: boolean;
    private button: HTMLElement;

    constructor( button: HTMLElement ) {
        this.isVisible = false;

        this.button = button;
        this.button.addEventListener('click', () => this.scrollToTop());
        window.addEventListener('scroll', e => this.update());
    }

    private update(){
        let y = window.scrollY;

        let visible = y > this.scrollYThreshold;

        if( visible === this.isVisible ){
            return;
        }

        this.isVisible = visible;
        this.button.classList.toggle(this.visibleClass, visible);
    }

    public scrollToTop(){
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

}