export class ContactForm {
    private form: HTMLFormElement;
    private fileUpload: HTMLInputElement;
    private submitButton: HTMLButtonElement;
    private fileError: HTMLElement;

    constructor(form: HTMLFormElement) {
        this.form = form;
        this.fileUpload = document.querySelector("#file");
        this.submitButton = document.querySelector("#SubmitButton");
        this.fileError = document.querySelector(".file-size-error");

        if (this.fileUpload) {
            this.fileUpload.addEventListener("change", () => {
                const fileList = this.fileUpload.files;
                let size = 0;
                for (const file of fileList) {
                    size += file.size;
                }
                const error = size / 1000 / 1000 > 25;
                this.submitButton.disabled = error;
                this.fileError.classList.toggle("visible", error);
            });
        }
    }
}
