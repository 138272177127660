import { Overlay } from "./Overlay";
import "./tooltips";
import "./SwiperGalleries";
import "./accordion-section";
import "./tabs-section";
import "./navigation";
import ScrollToTop from "./ScrollToTop";
import { ContactForm } from "./ContactForm";

const personOverViewSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".person-overview-section");

personOverViewSections.forEach((container) => {
    new Overlay(container);
});

const timetableBlocks = <NodeListOf<HTMLElement>>document.querySelectorAll(".timetable-block");

timetableBlocks.forEach((container) => {
    new Overlay(container);
});

const overlayImageBlocks = <NodeListOf<HTMLElement>>document.querySelectorAll(".overlay-image-block");

overlayImageBlocks.forEach((container) => {
    new Overlay(container);
});

const ticketsSection = <NodeListOf<HTMLElement>>document.querySelectorAll(".tables-pricing .table");

ticketsSection.forEach((container) => {
    new Overlay(container);
});

// scroll to top
const scrollToTopButton = <HTMLElement>document.querySelector("#BackToTop");
if (scrollToTopButton) {
    const scrollToTop = new ScrollToTop(scrollToTopButton);
}

const contactForms = <NodeListOf<HTMLFormElement>>document.querySelectorAll(".contact-form");

contactForms.forEach((contactForm) => {
    new ContactForm(contactForm);
});
